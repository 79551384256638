<template>
  <div class="uploadPictures">
    <div
      class="uploadClassBox"
      v-for="(item, index) in templateList"
      :key="index"
    >
      <div class="uploadTitle">{{ item.templateName }}</div>
      <div class="uploadGroup" v-for="(childItem, childIndex) in item.itemList" :key="childIndex">
        <!-- <div class="uploadTip"><span>*</span>{{ childItem.typeName }}</div>
        <div class="uploadBox">
          <ul>
            <ul>
              <van-uploader
                v-model="childItem.imageList"
                :after-read="afterRead"
                multiple
                preview-image
                preview-full-image
                :before-delete="imgDelete"
                :max-count="childItem.maximum"
              />
            
              <li
                class="uploadImg templateImage"
                v-show="childItem.imgUrlList == null ? false : true"
              >
                <img
                  class="showImg"
                  :src="childItem.imgUrlList[0]"
                  alt=""
                  @click="bigImg(childItem.imgUrlList, 0)"
                />
                <span class="showTip"
                  >{{ childItem.imgUrlList.length }}张示例图</span
                >
              </li>
            </ul>
          </ul>
        </div> -->
        <uploadWXImg :childItem="childItem" ></uploadWXImg>
      </div>
    </div>
  </div>
</template>

<script>
import uploadWXImg from "../uploadFile/uploadWXImg";
export default {
  components: {
    uploadWXImg,
  },
  props: {
    templateList: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      imgId: "", //图片id
      emImgs: [],
    };
  },
  mounted() {},
  methods: {
    // //图片预览
    // bigImg(imgs, index) {
    //   ImagePreview({
    //     images: imgs, // 预览图片的那个数组
    //     showIndex: true,
    //     startPosition: index, // 指明预览第几张图
    //   });
    // },
    // afterRead(file, id) {
    //   // console.log(file)
    //   // console.log(id)
    //   afterReadPubile(file, this.emImgs);
    // },
    // imgDelete(file, detail) {
    //   imgDeletePubile(detail, this.emImgs);
    // },
  },
};
</script>

<style>
</style>