<template>
  <div class="proofService">
    <div class="goBack">
      <i class="goBackClose" @click="jumpBack"></i>
    </div>
    <div class="proofTitle">上传服务凭证</div>
    <div class="proofTip">
      <span class="star">*</span
      ><span class="tip"
        >请上传维修信息，平台将会在第一时间审核，请您耐心等待（服务信息最多上传10次）</span
      >
    </div>
    <div class="proofContent">
      <div class="contentTitle">服务结果概述</div>
      <textarea
        v-model="inputProof[0].cnt"
        class="inputProof"
        maxlength="100"
        placeholder="请输入问题描述"
      ></textarea>
    </div>
    <!-- 海康产品 -->
    <haikangWX
      v-if="serviceOrderNo"
      :serialNumberList="serialNumberList"
    ></haikangWX>
    <!-- 产品信息 -->
    <productListWX
      v-if="productList == null ? false : true"
      :productList="productList"
    ></productListWX>
    <!-- 图片上传 -->
    <uploadWXGroup :templateList="templateList"></uploadWXGroup>
    <div class="proofConfirm" @click="confirmUp">确认提交</div>
    <van-overlay :show="show">
      <van-loading size="24px" vertical>上传中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { serviceVoucherTempletInfoWX, updateOrderTrackWX } from "../api/home";
import { dialog, loading, overlay } from "../utils/vant";
import haikangWX from "../components/uploadFile/haikangWX";
import productListWX from "../components/uploadFile/productListWX";
import uploadWXGroup from "../components/uploadFile/uploadWXGroup";
export default {
  components: {
    haikangWX,
    productListWX,
    uploadWXGroup,
  },
  data() {
    return {
      orderNo: this.$route.query.orderNo, //订单号
      openId: this.$route.query.openId, //唯一标识微信
      type: this.$route.query.type, //类型 1--获取 2--修改
      repairId: this.$route.query.repairId, //维修信息表Id
      trackId: this.$route.query.editTrackId, //修改轨迹的id
      trackCode: "10009", //轨迹编码
      requesstType: "1", //请求类型  1--微信 ,5--APP
      operatorType: "1", //操作类型
      trackType: "1", //轨迹类型
      inputProof: [{ cnt: "", urls: [] }], //服务结果概述
      serviceOrderNo: false, //海康订单标识--序列号
      haikangShow: false, //海康订单示例图显示标识
      serialNumberList: [
        {
          id: 1111,
          isRequired: 1,
          productContent: "",
          productName: "海康产品序列号",
          templetCode: "1111",
        },
      ], //海康产品序列号
      productList: [], //产品数组
      templateList: [], //模板数据
      show: false, //上传加载显示
    };
  },
  mounted() {
    this.getServiceVoucherTempletInfo();
    this.$store.state.type = this.type;
    this.$store.state.haikangShow = this.haikangShow;
  },
  methods: {
    // 获取服务凭证模板数据APP
    getServiceVoucherTempletInfo() {
      let obj = {
        orderNo: this.orderNo,
        openId: this.openId,
        type: this.type,
        repairId: this.repairId,
      };
      serviceVoucherTempletInfoWX(obj).then((res) => {
        if (res.status) {
          //控制海康订单示例图不显示
          if (res.serviceOrderNo) {
            this.haikangShow = true;
          }
          // 控制海康产品序列号显示
          if (res.serviceOrderNo != null && res.needSerialNum == 1) {
            this.serviceOrderNo = true;
          }
          // 服务概述赋值
          if (res.serviceInfo) {
            this.inputProof = res.serviceInfo;
          }
          // 控制产品信息显示-数组赋值
          this.productList = res.productList;
          //模板数据
          this.templateList = res.templateList;
        }
      });
    },
    // 提交上传
    confirmUp() {
      // 描述信息校验
      if (this.inputProof[0].cnt == "" || this.inputProof[0].cnt == undefined) {
        this.$dialog.alert({
          message: "描述信息不能为空",
        });
        return false;
      }
      // 海康产品序列号校验
      /*if (this.serviceOrderNo) {
        if (this.serialNumberList == "" || this.serialNumberList == null) {
          this.$dialog.alert({
            message: "海康产品序列号不能为空",
          });
          return false;
        }
      }
      // 产品信息校验
      if (this.productList != null) {
        var productList = this.productList;
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].isRequired == 1) {
            if (
              productList[i].productContent == "" ||
              productList[i].productContent == null
            ) {
              this.$dialog.alert({
                message: `${productList[i].productName}不能为空`,
              });
              return false;
            }
          }
        }
      }
      //图片上传校验
      if (!this.templateList) {
        this.$dialog.alert({
          message: "上传图片不能为空",
        });
        return false;
      } else {
        var templateList = this.templateList;
        for (var i = 0; i < templateList.length; i++) {
          var itemList = templateList[i].itemList;
          for (var j = 0; j < itemList.length; j++) {
            if (itemList[j].imageList.length == 0) {
              this.$dialog.alert({
                message: `${itemList[j].typeName}上传图片不能为空`,
              });
              return false;
            }
            if (itemList[j].imageList.length < itemList[j].minimum) {
              this.$dialog.alert({
                message: `${itemList[j].typeName}上传图片数量不能小于模板最小上传数量${itemList[j].minimum}`,
              });
              return false;
            }
            if (itemList[j].imageList.length > itemList[j].maximum) {
              this.$dialog.alert({
                message: `${itemList[j].typeName}上传图片数量不能大于模板最大上传数量${itemList[j].maximum}`,
              });
              return false;
            }
            // 审核不通过状态上传是变更为1
            if (itemList[j].status == 3) {
              itemList[j].status = 1
            }
          }
        }
      }*/
      let templateList = this.templateList;
      for (var i = 0; i < templateList.length; i++) {
        let itemList = templateList[i].itemList;
        for (var j = 0; j < itemList.length; j++) {
          // 审核不通过状态上传是变更为1
          if (itemList[j].status == 3) {
            itemList[j].status = 1;
          }
        }
      }
      //修改轨迹类型变更为4
      if (this.type == 2) {
        this.trackType = 4;
      }
      let productInfo = []; //海康序列号与产品信息选择其一
      if (this.serviceOrderNo) {
        productInfo = this.serialNumberList;
      } else {
        productInfo = this.productList;
      }
      this.show = true;
      // 请求参数
      let obj = {
        orderNo: this.orderNo,
        openId: this.openId,
        trackCode: this.trackCode,
        requesstType: this.requesstType,
        operatorType: this.operatorType,
        trackType: this.trackType,
        trackId: this.trackId,
        repairId: this.repairId,
        serviceInfo: JSON.stringify(this.inputProof),
        productInfo: JSON.stringify(productInfo),
        templetInfo: JSON.stringify(this.templateList),
      };
      updateOrderTrackWX(obj).then((res) => {
        if (res.status) {
          this.show = false;
          this.$dialog
            .alert({
              message: `${res.message}`,
            })
            .then(() => {
              window.history.go(-1);
            });
        } else {
          this.show = false;
          this.$dialog.alert({
            message: `${res.message}`,
          });
        }
      });
    },
    // 跳转返回
    jumpBack() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="less">
@import url("../styles/proofService.less");
</style>
