<template>
  <section>
    <div class="uploadTip"><span>*</span>{{ childItem.typeName }}</div>
    <div class="uploadBox">
      <ul>
        <!-- 上传的图片 -->
        <li v-for="(imgs, index) in childItem.imageList" :key="index">
          <img :src="imgs" alt="" @click="bigImg(childItem.imageList, index)" />
          <div v-if="type == 1">
            <i class="deleteImg" @click="deleteImg(index)"></i>
          </div>
          <div v-else>
            <i
              v-show="childItem.status == 3"
              class="deleteImg"
              @click="deleteImg(index)"
            ></i>
          </div>
        </li>
        <div v-if="type == 1">
          <li
            class="addButton"
            v-show="childItem.imageList.length < childItem.maximum"
          >
            <label :for="childItem.id" class="fileBtn">
              <img src="../../assets/images/proof/addImage.png" alt="" />
            </label>
            <input
              :id="childItem.id"
              style="display: none"
              ref="referenceUpload"
              type="file"
              multiple
              accept="image/*"
              @change="replyFun"
            />
          </li>
        </div>
        <div v-else>
          <li
            class="addButton"
            v-show="
              childItem.imageList.length < childItem.maximum &&
              childItem.status == 3
            "
          >
            <label :for="childItem.id" class="fileBtn">
              <img src="../../assets/images/proof/addImage.png" alt="" />
            </label>
            <input
              :id="childItem.id"
              style="display: none"
              ref="referenceUpload"
              type="file"
              multiple
              accept="image/*"
              @change="replyFun"
            />
          </li>
        </div>
        <!-- 示例图数组模板 -->
        <li
          class="uploadImg templateImage"
          v-show="childItem.imgUrlList.length > 0 && !haikangShow"
        >
          <img
            class="showImg"
            :src="childItem.imgUrlList[0]"
            alt=""
            @click="bigImg(childItem.imgUrlList, 0)"
          />
          <span class="showTip">{{ childItem.imgUrlList.length }}张示例图</span>
        </li>
      </ul>
    </div>
    <van-overlay :show="show">
      <van-loading size="24px" vertical>上传中...</van-loading>
    </van-overlay>
  </section>
</template>

<script>
import { ImagePreview } from "vant";
import { uploadFile, ossUploadFile } from "../../api/home";
export default {
  props: {
    childItem: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      type: '',
      haikangShow: false,
    };
  },
  mounted() {
    this.type = this.$store.state.type;
    this.haikangShow = this.$store.state.haikangShow;
  },
  methods: {
    // 删除图片
    deleteImg(index) {
      this.childItem.imageList.splice(index, 1);
    },
    //图片预览
    bigImg(imgs, index) {
      ImagePreview({
        images: imgs, // 预览图片的那个数组
        showIndex: true,
        startPosition: index, // 指明预览第几张图
      });
    },
    replyFun(file) {
      this.show = true;
      let fileList = file.target.files;
      fileList.forEach((e, i) => {
        let fileName = e.name;
        uploadFile().then((res) => {
          let formData = new FormData();
          formData.append("name", e.name);
          formData.append("key", `${res.dir}`);
          formData.append("policy", res.policy);
          formData.append("OSSAccessKeyId", res.accessid);
          formData.append("signature", res.signature);
          formData.append("success_action_status", "200");
          formData.append("file", e);
          ossUploadFile(formData);
          setTimeout(() => {
            this.childItem.imageList.push(res.accessUrl);
            this.show = false;
            if (this.childItem.imageList.length > this.childItem.maximum) {
              this.childItem.imageList.splice(i, 1);
              this.$dialog.alert({
                message: `上传图片的数量不能超过模板配置的最大值${this.childItem.maximum}`,
              });
            }
          }, 2000);
        });
      });
      document.getElementById(this.childItem.id).value = "";
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>