<template>
  <div class="productSerialNumber">
    <div v-for="(item, index) in productList" :key="index">
      <div class="contentTitle">
        {{ item.productName }}
        <div class="scanCode" v-if="show" @click="scanningCode(item.id)">
          <i class="scanCodeIcon"></i>扫码
        </div>
      </div>
      <div class="contentTip">
        <span v-show="item.isRequired == 1 ? true : false">*</span
        >可手动输入或直接扫二维码添加
      </div>
      <textarea
        v-model="item.productContent"
        class="serialNumber"
        placeholder="请填写实际设备序列号!多组序列号请用英文分号隔开! 例如：C87895885;C87895847"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      id: '',
      show: false,
    };
  },
  mounted() {},
  methods: {
    scanningCode(id) {
      this.id = id
      this.productList.forEach(e => {
        if ((e.id == this.id)) {
          var res = '11111';
          if (e.productContent) {
            e.productContent = `${e.productContent};${res}`;
          } else {
            e.productContent = res;
          }
        }
      });
    },
  },
};
</script>

<style>
</style>