<template>
  <div class="productSerialNumber">
    <div class="contentTitle">
      海康产品序列号
      <div class="scanCode" v-if="false" @click="haikangScanningCode">
        <i class="scanCodeIcon"></i>扫码
      </div>
    </div>
    <div class="contentTip"><span>*</span>可手动输入或直接扫二维码添加</div>
    <textarea
      v-model="serialNumberList.productContent"
      class="serialNumber"
      placeholder="请填写实际设备序列号!多组序列号请用英文分号隔开! 例如：C87895885;C87895847"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    serialNumberList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    haikangScanningCode() {
      var res = "111115";
      if (this.serialNumberList.productContent) {
        this.serialNumberList.productContent = `${this.serialNumberList.productContent};${res}`;
      } else {
        this.serialNumberList.productContent = res;
      }           
    },
  },
};
</script>

<style>
</style>